body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.product_card {
  background-color: white;
}

.slick-slide div {
  outline: none;
}

.slick-prev {
  left: 0rem !important;
  width: 3rem !important;
  height: 3rem !important;
  z-index: 50 !important;
}

.slick-next {
  right: 0rem !important;
  width: 3rem !important;
  height: 3rem !important;
  z-index: 50 !important;
}
